.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollContent{
  height: 100vh;
  overflow-Y: auto;
  border-left: 1px solid #000000;
}

.small-dragger-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Adjust the height as needed */
}

.small-dragger {
  width: 200px; /* Adjust the width as needed */
  height: 100px; /* Adjust the height as needed */
  border: 2px dashed #d9d9d9; /* Change the border color as needed */
  border-radius: 4px; /* Adjust border-radius as needed */
  background-color: #fafafa; /* Change background color as needed */
}

.ant-upload-drag-icon {
  font-size: 24px; /* Adjust the icon size as needed */
  color: #1890ff; /* Change the icon color as needed */
}

.ant-upload-text {
  margin-top: 8px; /* Adjust the spacing between icon and text as needed */
  color: #999; /* Change the text color as needed */
}

body, html {
  height: 100%;
  margin: 0;
}

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  text-align: center;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ant-layout .ant-layout-sider-children {
  height: 85vh;
  margin-top: -0.1px;
  padding-top: 0.1px;
  overflow-y: auto;
}